import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Container from 'reactstrap/lib/Container';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { getImageUrl, SOURCES } from 'client/utils/image-helpers';

import './vehicle-showcase-controls.scss';

function VehicleShowcaseControlsTile({
  slideContent,
  updateSelectedSlide,
  slideIndex,
  selectedSlideIndex,
  slideId,
  isLazyImages,
}) {
  const { thumbTitle, thumbImage } = slideContent;

  const vehicleImage = getImageUrl(thumbImage, null, SOURCES.STATIC);
  const isActive = slideIndex === selectedSlideIndex;
  return (
    <Button
      color=""
      onClick={updateSelectedSlide}
      value={slideIndex}
      className={classnames('vehicle-control-button medium w-100 d-flex flex-column align-items-center p-0', {
        'selected-button': isActive,
      })}
      role="tab"
      id={`tab-${slideId}`}
      aria-selected={isActive}
      aria-controls={`tabpanel-${slideId}`}
    >
      <img src={vehicleImage} alt="" height={58} loading={isLazyImages ? 'lazy' : undefined} className="rounded-8" />
      <ContentFragment classes="text-gray-darker fw-medium py-0_75" componentToUse="span">
        {thumbTitle}
      </ContentFragment>
    </Button>
  );
}

VehicleShowcaseControlsTile.propTypes = {
  slideContent: PropTypes.shape({
    thumbTitle: PropTypes.string,
    thumbImage: PropTypes.string,
  }).isRequired,
  updateSelectedSlide: PropTypes.func.isRequired,
  slideIndex: PropTypes.number.isRequired,
  selectedSlideIndex: PropTypes.number.isRequired,
  slideId: PropTypes.string.isRequired,
  isLazyImages: PropTypes.bool,
};

VehicleShowcaseControlsTile.defaultProps = {
  isLazyImages: false,
};

export function VehicleShowcaseControls({
  slides,
  updateSelectedSlide,
  selectedSlideIndex,
  isLazyImages,
  isMediumLayout,
  useContainer,
}) {
  const slideCount = slides.length;
  const Wrapper = useContainer ? Container : Fragment;

  return (
    <Wrapper>
      <div className={classnames('vehicle-showcase-controls pos-r ', { 'px-lg-2 mx-lg-2': !isMediumLayout })}>
        <Row role="tablist">
          {slides.map((slideItem, index) => (
            <Col xs={12 / slideCount} key={slideItem.id} className="px-1_25 d-flex" role="presentation">
              <VehicleShowcaseControlsTile
                key={slideItem.id}
                slideContent={slideItem.getAllMetadata()}
                updateSelectedSlide={updateSelectedSlide}
                slideIndex={index}
                selectedSlideIndex={selectedSlideIndex}
                slideId={slideItem.id}
                isLazyImages={isLazyImages}
              />
            </Col>
          ))}
        </Row>
      </div>
    </Wrapper>
  );
}

VehicleShowcaseControls.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateSelectedSlide: PropTypes.func.isRequired,
  selectedSlideIndex: PropTypes.number.isRequired,
  isLazyImages: PropTypes.bool,
  isMediumLayout: PropTypes.bool,
  useContainer: PropTypes.bool,
};

VehicleShowcaseControls.defaultProps = {
  isLazyImages: false,
  isMediumLayout: false,
  useContainer: false,
};
